import React from "react";

function Phd() {
  return (
    <>
      <div className="btns">
        <div className="row row-1">
          <div className="col-md-4 BAT courses-rows">Engineering</div>
          <div className="col-md-4 courses-rows">Management</div>
          <div className="col-md-4 courses-rows">Commerce</div>
        </div>
        <div className="row row-2">
          <div className="col-md-4 courses-rows" style={{ paddingTop: "10px" }}>
            Science
          </div>
          <div className="col-md-4 courses-rows" style={{ paddingTop: "10px" }}>
            Pharmacy
          </div>
          <div className="col-md-4 courses-rows">
            Arts, Humanities & Social Science
          </div>
        </div>
      </div>
    </>
  );
}

export default Phd;
