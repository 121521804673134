import React from "react";

function PostGraduate() {
  return (
    <>
      <div>
        <div className="btns">
          <div className="row row-1">
            <div className="col-md-4 BAT courses-rows">Master of Arts <br></br>(MA)</div>
            <div className="col-md-4 courses-rows">Master of Pharmacy <br></br>(M.Pharm.)</div>
            <div className="col-md-4 courses-rows"> 
              Master of Computer Application<br></br>(MCA)
            </div>
          </div>
          <div className="row row-2">
            <div className="col-md-4 BAT courses-rows">
              <div class="dropdown" style={{ border: "none" }}>
                <button
                  class="drop-btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Master of Science <br></br>(M.Sc.)
                </button>
                <div
                  class="dropdown-menu menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <ul>
                    <li>Physics</li>
                    <li>Chemistry</li>
                    <li>Maths</li>
                    <li>Forensic Science</li>
                    <li>Agriculture</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 BAT courses-rows">
              <div class="dropdown" style={{ border: "none" }}>
                <button
                  class="drop-btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Master of Business Administration <br></br> (MBA)
                </button>
                <div
                  class="dropdown-menu menu"
                  aria-labelledby="dropdownMenuButton"
                  style={{ border: "none" }}
                >
                  <ul>
                    <li>Finance</li>
                    <li>Foreign Trade</li>
                    <li>Human Resource</li>
                    <li>Logistic Supply Chain Management</li>
                    <li>Marketing Management</li>
                    <li>Business Analytics</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 BAT courses-rows">
              <div class="dropdown" style={{ border: "none" }}>
                <button
                  class="drop-btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Master of Technology <br></br>(M.Tech.)
                </button>
                <div
                  class="dropdown-menu menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <ul>
                    <li>Computer Science and Engineering</li>
                    <li>Civil Engineering</li>
                    <li>Electronics & Communication</li>
                    <li>Electrical Engineering</li>
                    <li>Information Technology</li>
                    <li>Mechanical Engineering</li>
                    <li>Nano Technology</li>
                    {/* <li>Master of Computer Application(MCA)</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </>
  );
}

export default PostGraduate;
