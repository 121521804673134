import React from "react";

function UnderGraduate() {
  return (
    <>
      <div className="btns">
        <div className="row row-1">
          <div className="col-md-4 BAT courses-rows">
            <div class="dropdown" style={{ border: "none", color: "black" }}>
              <button
                class="drop-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "black" }}
              >
                Bachelor of Technology <br></br> (B.Tech.)
              </button>
              <div
                class="dropdown-menu menu"
                aria-labelledby="dropdownMenuButton"
                style={{
                  border: "none",
                  fontFamily: "Gotham-Medium",
                }}
              >
                <ul>
                  <li>Computer Science and Engineering</li>
                  <li>Information Technology</li>
                  <li>AU-Electric Vehicle</li>
                  <li>Civil Engineering</li>
                  <li>Electrical Engineering</li>
                  <li>Mechanical Engineering</li>
                  <li>Electronics & Communication</li>
                  <li>Robotics and Automation</li>
                  <li>Artificial Intelligence (AI)</li>
                  <li>Data Science (DS)</li>
                  <li>Computer Science and Buisness System (CSBS)</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 courses-rows" style={{ letterSpacing: "0" }}>
            Bachelor of Pharmacy <br></br>(B.Pharm.)
          </div>
          <div className="col-md-4 BAT courses-rows">
            <div class="dropdown">
              <button
                class="drop-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "black" }}
              >
                Bachelor of Business Administration <br></br>(BBA)
              </button>
              <div
                class="dropdown-menu menu"
                aria-labelledby="dropdownMenuButton"
                style={{
                  border: "none",
                  fontFamily: "Gotham-Medium",
                }}
              >
                <ul>
                  <li>Foreign Trade</li>
                  <li>Human Resource</li>
                  <li>Marketing Management</li>
                  <li>Digital Marketting</li>
                  <li>Finance</li>
                  <li>Business Analytics</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-2">
          <div className="col-md-4 BAT courses-rows">
            <div class="dropdown" style={{ border: "none" }}>
              <button
                class="drop-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Bachelor of Commerce <br></br>(B.Com.-Hons.)
              </button>
              <div
                class="dropdown-menu menu"
                aria-labelledby="dropdownMenuButton"
                style={{ border: "none", fontFamily: "Gotham-Medium" }}
              >
                <ul>
                  <li>Accountig & Taxation</li>
                  <li>Banking & Insurance</li>
                  <li>Computer Application</li>
                  <li>Global Finance</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 courses-rows" style={{ letterSpacing: "0" }} >
            Bachelor of Computer Applications <br></br>(BCA)
          </div>
          <div className="col-md-4 BAT courses-rows">
            <div class="dropdown" style={{ border: "none" }}>
              <button
                class="drop-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "black" }}
              >
                Bachelor of Science <br></br>(B.Sc.-Hons.)
              </button>
              <div
                class="dropdown-menu menu"
                aria-labelledby="dropdownMenuButton"
                style={{ border: "none" }}
              >
                <ul>
                  <li>Bio-Technology</li>
                  <li>Forensic Science</li>
                  <li>Computer Science</li>
                  <li>Agriculture</li>
                  <li>Anaesthesia & Operation Theatre Technology (A&OTT)</li>
                  <li>Medical Laboratory Technology (MLT)</li>
                  <li>Cardiovascular Technology (CVT)</li>
                  <li>Respiratory Technology (RT)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-2">
          <div className="col-md-4 BAT courses-rows">
            <div class="dropdown" style={{ border: "none" }}>
              <button
                class="drop-btn"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Bechelor & Integrated <br></br>Law
              </button>
              <div
                class="dropdown-menu menu"
                aria-labelledby="dropdownMenuButton"
                style={{ border: "none", fontFamily: "Gotham-Medium" }}
              >
                <ul>
                  <li>BA-LL.B. (Hons.)</li>
                  <li>BBA-LL.B.(Hons.)</li>
                  <li>LL.B.(Hons.)</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4 courses-rows" style={{ letterSpacing: "0" }}>
            Bachelor of Journalism & Mass Communication (BJMC)
        </div>
        </div>
      </div>
    </>
  );
}

export default UnderGraduate;
